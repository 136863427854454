.cardBackground {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
}

.card {
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: 512px;
    max-height: 90vh;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    text-align: center;
    z-index: 2;
}

.card p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

.user {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    padding: 0px;
}

.profilePictures {
    display: block;
    flex-shrink: 0;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: cover;
    object-position: center top;
}

.interests {
    display: block;
    overflow-y: auto;
    width: 100%;
}

.interest {
    font-size: 1em;
    display: inline-block;
    overflow-wrap: normal;
    border: 1px solid #6458f4;
    border-radius: 4px;
    padding: 2px;
    margin: 2px;
}

.bio {
    font-style: italic;
}

.cardClose {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
}

.closeIcon {
    color: rgba(255, 255, 255, 0.75);
    background-color: rgba(255, 255, 255, 0.25);
}