.chip {
    display: inline-block;
    border: 1px solid #6458f4;
    border-radius: 4px;
    color: black;
    cursor: pointer;
    user-select: none;
    margin: 4px;
    padding: 2px;
}

.chipActive {
    background-color: #6458f4;
    color: white;
}