.imageUploader {}

.previewImage {
    width: 100%;
    max-height: 100%;
    /* To hide the alt text when the image isn't loaded */
    color: transparent;
}

.input {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inputs {
    margin-bottom: 24px;
}