.iconContainer {
    width: 40px;
    height: 40px;
    margin: 2px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    background: none;
    color: black;
}

.iconContainer:hover {
    background: #88888822;
}

.iconContainer:active .iconContainer:target {
    background: #88888844;
}

.icon {
    min-height: 80%;
    width: auto;
}

