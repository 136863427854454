.topbar {
    display: flex;
    flex-direction: row;
    align-items: unset;
    justify-content: flex-end;
    min-height: 48px;
    height: 48px;
    padding: 0px;
    text-align: left;
    width: calc(100% - 48px);
    max-width: 512px;
    margin: 10px 24px 0px;
}

.topbar h1 {
    margin-right: auto;
    padding-left: 0px;
}