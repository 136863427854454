.button {
    display: block;
    margin: 24px auto;
    width: 80%;
    background-color: #6458f4;
    text-align: center;
    border: 0;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 16px;
    text-decoration: none;
}

.button:hover {
    background-color: #7b71f1;
}

.button:active {
    background-color: #544ac6;
}

.smallMargins {
    margin: 4px auto;
}

.shrink {
    width: unset;
    margin: 4px;
}

.disabled {
    background: white !important;
    border: #6458f4;
    border-style: solid;
    border-width: 2px;
    pointer-events: none;
    color: #000;
}