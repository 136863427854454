.outer {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.inner {
    display: block;
    position: relative;
    width: 100%;
}

.headerBox {
    text-align: center;
    width: 100%;
    padding: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.buttonBox {
    width: 100%;
}

.noteBox {
    width: 100%;
    padding: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.title {
    font-size: 3em;
    font-style: italic;
}