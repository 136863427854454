.logout {
    bottom: 0;
    left: 0;
    width: 100%;
}

.logout Button {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 512px;
}

.tagline {
    margin-bottom: 8px;
    font-style: italic;
}

.intro {
    display: block;
    width: 100%;
    max-width: 512px;
    background-color: white;
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 20px;
    align-items: center;
    text-align: left;
}

.intro p {
    margin: 0px;
    font-style: italic;
}