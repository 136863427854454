.notification {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 84px;
    align-items: center;
    padding: 4px;
    overflow: hidden;
}

.details {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.column {
    flex-direction: column;
    align-items: flex-start;
}

.icon {
    display: block;
    height: 48px;
    width: 48px;
    min-width: 48px;
    margin: 4px;
    object-fit: cover;
    object-position: center top;
    border-radius: 50%;
}

.text {
    display: inline-block;
    flex-grow: 1;
    overflow: hidden;
    margin: 4px;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: flex-start;
}

.button {
    color: white;
    padding: 4px;
    width: 24px;
    height: 24px;
    margin: 4px;
    box-sizing: content-box;
}

.accept {
    background-color: green;
}

.decline {
    background-color: crimson;
}

.close {
    background-color: gray;
}