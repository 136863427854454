.outer {
    background: #eee;
    padding: 24px;
    border-radius: 8px;
    vertical-align: middle;
    position: relative;
}

.contactLine {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contactIcon {
    margin-right: 4px;
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
}