h1 {
    margin-top: 0;
    font-style: italic;
    font-weight: bold;
}

input {
    width: 100%;
    padding: 4px;
}

label {
    display: block;
    margin-top: 8px;
    font-style: italic;
    font-weight: bold;
}