.form {
    width: 100%;
    min-width: 300px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
}

.form textarea {
    min-width: 250px;
}