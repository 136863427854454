.main {
    display: block;
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
}

.mainCentered {
    display: flex;
    flex-grow: 1;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.outer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
}

.inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: auto;
    height: auto;
    max-width: 512px;
    margin: 0px 24px 24px;
    overflow: hidden;
}

.rounded {
    border-radius: 8px;
}