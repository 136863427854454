.card {
    display: block;
    width: 100%;
    max-width: 512px;
    background-color: white;
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    text-align: center;
}

.eventReq {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
}

.user {
    display: flex;
    flex-direction: column;
    width: 40%;
    max-height: 100%;
    padding: 4px;
    margin-top: 10px;
}

.request {
    width: 60%;
    padding: 4px;
    text-align: left;
}

.request p {
    margin: 10px 0px;
}

.request Button {
    margin-left: 0px;
}

.description {
    font-style: italic;
}

.profilePicture {
    display: block;
    flex-shrink: 0;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin: auto;
    object-fit: cover;
    object-position: center top;
}

.interests {
    display: block;
    overflow-y: auto;
    width: 100%;
}

.interest {
    font-size: 1em;
    display: inline-block;
    overflow-wrap: normal;
    border: 1px solid #6458f4;
    border-radius: 4px;
    padding: 2px;
    margin: 2px;
}

.confirmation {
    background: #b1abf9;
    padding: 16px;
    margin-bottom: 8px;
    border-radius: 8px;
    position: relative;
}

.confirmationClose {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
}

.requestingUsers {
    font-style: italic;
}

.reqUser {
    font-style: normal;
    font-size: 1em;
    display: inline-block;
    overflow-wrap: normal;
    border: 1px solid #6458f4;
    border-radius: 4px;
    padding: 2px;
    margin: 2px;
}

.reqUser p {
    margin: 2px;
}

.reqUserPicture {
    display: block;
    flex-shrink: 0;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    margin: auto;
    object-fit: cover;
    object-position: center top;
}
